.exercise-type-select {
    /*
    display: flex;
    flex-direction: column;
    */
    display: grid;
    grid-template-rows: 3rem auto;
    height: 100%;
}

.exercise-type-select-name-input {
    flex-grow: 1;
    /*border-bottom: 1px solid #CCC; */
    border-bottom: 1px solid var(--border-color);
    padding: 2em;  
}

.exercise-type-select-tags {
    box-sizing: content-box;
    /*
    display: flex;
    flex-direction: column;
    */
    overflow-y: auto;
    /*box-shadow: inset 0px 2px 5px 0px #00000042, inset 0px 2px 10px #00000029;*/
    scroll-behavior: smooth;
    webkit-overflow-scrolling: touch;
}

.exercise-type-select-tag-header {
    color: var(--font-color-secondary);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.7em;
    padding-left: 0.8em;
    border-bottom: 1px solid var(--border-color);
    font-size: 1.1em;
    font-weight: bold;
}

.exercise-type-select-tag {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding: 0.7em;
    padding-left: 0.8em;
    border-bottom: 1px solid var(--border-color);
    font-size: 1.1em;
    font-weight: 500;
    color: var(--font-color-primary);
    cursor: pointer;
}

.exercise-type-select-tag.selected {
    background-color: var(--background-color-selection);
    color: var(--font-color-selection);
    border-color: var(--border-color-selection);
}

.exercise-type-select-tag-name {
    padding-left: 1em;
}

.exercise-type-select-types {
    box-sizing: content-box;
    /*
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    */
    overflow-y: auto;
    /*box-shadow: inset 0px 2px 5px 0px #00000042, inset 0px 2px 10px #00000029;*/
    scroll-behavior: smooth;
    webkit-overflow-scrolling: touch;
}

.exercise-type-select-exercise-row {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.7em;
    padding-left: 0.8em;
    border-bottom: 1px solid var(--border-color);
    font-size: 1.1em;
    font-weight: 500;
    color: var(--font-color-primary);
    cursor: pointer;
}

.exercise-type-select-exercise-row.selected {
    background-color: var(--background-color-selection);
    color: var(--font-color-selection);
    border-color: var(--border-color-selection);
}