.workout-layout {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1.5em;
    padding-right: 1.5em;
    padding-bottom: calc(var(--footer-height) + 3em);
    padding-top: 1.5em;
}

section.workout-summary {
    background-color: var(--background-color-content);
    color: var(--font-color-primary);
    border-radius: var(--border-radius-l);
    box-shadow: var(--shadow-s);
    padding: 0.4em;
    padding-bottom: 0.5em;
    margin-bottom: 2em;    
}

.workout-total-duration {
    position: fixed;
    bottom: calc(var(--footer-height) + 1.5em);
    padding: 1em;
    border: 2px solid #777;
    border-left: none;
    border-top-right-radius: var(--border-radius-l);
    border-bottom-right-radius: var(--border-radius-l);
    background-color: #fff;
    /* width: 100vw; */
    box-shadow: 0px 4px 1px #0004;
    z-index: 1;
}
