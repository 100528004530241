nav {
    background-color: var(--background-color-top-navigation);
    color: var(--font-color-attention);
    /* background: linear-gradient(var(--bg-color-darker), var(--secondary-bg-color) 40%); */
    padding: 0px;
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    top: 0px;
    left: 0px;
    height: var(--navigator-height);
    width: 100%;
    /*border-bottom: var(--border-header);*/
    /*
    box-shadow: 0px 0.1em 0.5em #000000ad, 0px 1px 2px #00000042, 0px 13px 2px #0000001a, inset 0px 7px 4px #0000009a;
    */
    border-bottom: none;
    /*box-shadow: 0px 0.25em 1px #00000042;*/
    /*box-shadow: var(--shadow-l);*/
    z-index: 999;
}
/*
@media (orientation: landscape) {
    nav {
        flex-direction: column;
        height: 100%;
        width: var(--navigator-width);
        padding-top: var(--navigator-height);
        padding-bottom: var(--footer-height);
    }
}*/

nav .navigation-link {
    background: var(--background-navigator-link);
    width: 100%;
    max-width: 12em;
    font-size: 1.2em;
    font-weight: 600;
    /*padding: 9px;*/
    margin-top: 0px;
    cursor: pointer;
    align-self: center;
    height: 100%;
    flex-grow: 1;
    text-align: center;

    display: flex;
    justify-content: center;
    flex-direction: column;
    /*
    border-right: 0.05em solid rgba(0, 0, 0, 0.3);
    border-left: 0.05em solid rgba(0, 0, 0, 0.3);
    */
    /*
    box-shadow: 0px 0px 0px #ec780000;
   
    transition: box-shadow 0.2s, background 0.2s;
     */
}

nav .navigation-link a {
    color: var(--font-color-1);
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 1.0em;
}

nav .navigation-link.first {
    border-left: 0;
}

nav .navigation-link.last {
    border-right: 0;
}

nav .navigation-link.active {
    background-color: var(--background-color-attention);
    color: var(--font-color-attention);
    /*
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    */
}

.nav-label {
    margin-left: 0.4em;
    font-family: var(--font-family-header);
    letter-spacing: 0.05em;
    font-weight: 500;
    font-size: 1.1em;
}
/*
@media (orientation: landscape) {
    .nav-label {
        display: none;
    }
}*/