.front-page-step {
    padding: 2em;
    text-align: center;
    width: min(100vw,850px);
    margin-left: auto;
    margin-right: auto;
}

.front-page-app-logo-container {
    /*
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 0em;
    margin-bottom: 1em;
    */
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
}

@media (orientation: portrait) {
    .front-page-app-logo-container {
        grid-template-areas: "logo" "title" "sub-title" "info-1" "info-2";
        grid-template-columns: 100%;
        grid-template-rows: auto;        
    }
}

@media (orientation: landscape) {
    .front-page-app-logo-container {
        grid-template-areas: "logo title" "logo sub-title" "logo info-1" "logo info-2";
        grid-template-columns: 40% 60%;
        grid-template-rows: auto;        
    }
}

.front-page-app-logo-container img {
    grid-area: logo;
    justify-self: center;
}

.front-page-app-logo-container h1 {
    grid-area: title;
    justify-self: center;
    margin: 0px;
    margin-top: 0.3em;
    line-height: 1em;
    font-size: 4em;
    font-weight: 550;
}

.front-page-app-logo-container h2 {
    grid-area: sub-title;
    justify-self: center;
    margin: 0px;
    margin-bottom: 0.9em;
    line-height: 0.5em;
    letter-spacing: 0.54em;
    font-size: 1.5em;
    padding-left: 0.54em;
}

.front-page-app-logo-container h3 {
    grid-area: info-1;
    opacity: 0.6;
    font-size: 1.2em;
    line-height: 1.2em;
    font-family: var(--font-family-content);
    margin-bottom: 0.7em;
}

.front-page-app-logo-container h4 {
    grid-area: info-2;
    opacity: 0.6;
    font-size: 1.4em;
    line-height: 1em;
    letter-spacing: 0.2em;
}

#front-page-footer {
    flex-grow: 1;
    background-color: #444;
    color: #ccc;
    padding: 2em;
    padding-left: 25vw;
    padding-right: 25vw;
    text-align: center;
}