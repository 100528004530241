section.cirquit-details-x {
    position: relative;
    /*background-color: #fff;*/
    width: 100%;
    display: grid;
    grid-template-areas:
        "title repeats"
        "exercises exercises";
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    margin-bottom: 1em;
    /* border: 2px solid #ccc; */
    border-radius: var(--border-radius-l);
    /*box-shadow: var(--shadow-m);*/
    padding: 0.5em 0.75em 0.5em 0.75em;
    overflow: hidden;
    /*background: linear-gradient(135deg, #efefef 0%, #f1f1f1 1%, #fff 2%, #fff 96%, #ececec 98.5%, #fff 100%);*/
    /* background: linear-gradient(to bottom, #fff 70%, #0001 100%); */
    /* border-radius: var(--border-radius-m); */
}

section.cirquit-details {
    padding: 0.5em 0.75em 0.5em 0.75em;
}

section.cirquit-details:after {
    content: "";
    display: inline-block;
    width: 0.25em;
    height: 0.25em;
    border: 0px solid #00000026;
    box-shadow: var(--shadow-l);
    position: absolute;
    right: 0;
    bottom: 0;
}

section.cirquit-details:last-of-type {
    margin-bottom: 0;
}

.title {
    grid-area: title;
    color: var(--font-color-secondary);
    font-size: 0.9em;
    font-weight: 700;
    margin-bottom: 0.2em;
    text-align: left;
}

.repeats {
    grid-area: repeats;
    color: var(--font-color-secondary);
    font-size: 1.2em;
    font-weight: 700;
    margin-bottom: 0.2em;
    text-align: right;
}

.exercises-table {
    /*grid-area: exercises;*/
    width: 100%;    
}

table.exercises-table td, table.exercises-table th {
    padding: 0;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
}

.exercise-title {
    display: flex;
    flex-direction: row;
}

.index {
    margin-right: 0.2em;
    min-width: 0.5em;
}